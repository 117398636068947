/**
 * Application Title
 * The title displayed in the application.
 */
export const appTitle = 'SUPERIS - Supplier Performance & Risk Management Tool';

/**
 * Application Version
 * The current version of the application.
 */
export const version = '3.4';

/**
 * Header Logo Path
 * The path to the logo displayed in the header.
 */
export const headerLogo = './assets/images/SE_Logo_Color_RGB.svg';

/**
 * Main Navigation Links
 * Array of objects representing the main navigation links in the application.
 */
export const mainLinks = [
  {
    title: 'SUPERIS',
    label: 'data source and risk evaluation',
    link: {
      label: 'Risk Evaluation',
      href: '/#/risks',
      target: '',
    },
    bottomLink: {
      label: 'Request Access',
      href: 'mailto:scm_data_analytics@siemens-energy.com?subject=SUPERIS%20Access%20Request&amp;body=Dear11%20Team%2C%20%0AI%20would%20like%20to%20request%20access%20to%20SUPERIS.%20%0AMy%20Name%20is%3A%20________________%0AMy%20GID%20is%3A%20_____________%0A%0AI%20need%20access%20for%20Segment%2Fs%3A%20______________%20%26%20Location%2Fs%3A%20_______________%0A(please%20include%20%E2%80%9Call%E2%80%9D%20or%20the%20ones%20you%20need%20writing%20access%20to)%0A',
      target: '_blank',
    },
    image: '/assets/images/risk-assessment.png',
  },
  {
    title: 'PARSE 360',
    label: 'visualization & analytics',
    link: {
      label: 'Parse 360',
      href: 'https://siemens-energy-datasense.eu.qlikcloud.com/sense/app/f0b4dc70-3f7e-4a7e-a511-72e0ee053bfd/sheet/bb7f6e1e-d314-4122-a166-ac91df61fdf0/state/analysis',
      target: '_blank',
    },
    bottomLink: {
      label: 'Request Access',
      href: 'mailto:scm_data_analytics@siemens-energy.com?subject=PARSE%20360%20Access%20Request&amp;body=Dear%20Team,%0A%0AI%20would%20like%20to%20request%20access%20to%20PARSE 360.%0A%0AMy%20Name%20is:________%0A%0AMy%20GID%20is:________%20',
      target: '_blank',
    },
    image: '/assets/images/parse-360.png',
  },
];

/**
 * Header Navigation Links
 * Array of objects representing the header navigation links.
 */
export const headerLinks = [
  {
    title: 'Home',
    link: {
      href: '/#/',
      target: '',
    },
  },
  {
    title: mainLinks[0].link.label,
    link: mainLinks[0].link,
  },
  {
    title: 'Action plans',
    link: {
      label: 'Action plans',
      href: '/#/action-plans',
      target: '',
    },
  },
  {
    title: mainLinks[1].link.label,
    link: mainLinks[1].link,
  },
  {
    title: 'SCRM SharePoint',
    link: {
      label: '',
      href: 'https://siemensenergyag.sharepoint.com/:f:/r/sites/Functions-Procurement/Shared%20Documents/Supply%20Chain%20Risk%20Management%20%26%20Market%20Intelligence/010%20-%20Supplier%20Performance%20%26%20Risk%20Management/010%20-%20Supplier%20Performance%20%26%20Risk%20Management%20Process/020%20-%20Digitalization/SUPERIS/SUPERIS%20Shared%20Community%20Info%20%26%20News?csf=1&web=1&e=vMDey8',
      target: '_blank',
    },
  },
];

/**
 * Footer Links
 * Array of objects representing the footer links.
 */
export const footerLinks = [
  {
    title: 'Report Issue',
    href: 'https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/159d2c94-2947-4b9b-963c-092dc6aa8b45?hidenavbar=true&Lookup=22',
  },
  {
    title: 'View all issues',
    href: 'https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/03f3aceb-45f9-4225-9b51-9809a20d17b8?hidenavbar=true&Lookup=22',
  },
];

/**
 * Team List
 * Array of objects representing team members.
 */
export const teamList = [
  {
    name: 'Christoph Schmieder',
    role: 'Functional Product Owner',
    organisation: 'SENERGY DE, SE P COE SSI',
    email: 'christoph.schmieder@siemens-energy.com',
  },
  {
    name: 'Nora Neumann',
    role: 'Cluster Lead Risk, Resilience & Supply Market Intelligence',
    organisation: 'SGRE COG P&LOG GOV SPE SPM',
    email: 'nora.neumann@siemensgamesa.com',
  },
  {
    name: 'Marita Hustert',
    role: 'Data Domain Manager',
    organisation: 'SENERGY DE, SE P COE DDM BA',
    email: 'marita.hustert@siemens-energy.com',
  },
  {
    name: 'Alexandru Vaju',
    role: 'Project Manager',
    organisation: 'SENERGY DE, SE P COE SSI',
    email: 'alexandru.vaju@siemens-energy.com',
  },
  {
    name: 'Mohamad-Ali Mortada',
    role: 'Product Manager',
    organisation: 'SE DC APB PLM CAT',
    email: 'mohamad-ali.mortada@siemens-energy.com',
  },
  {
    name: 'Julian Kunschke',
    role: 'Backend Engineer',
    organisation: 'SE DC APB PLM CAT',
    email: 'julian.kunschke@siemens-energy.com',
  },
  {
    name: 'Fátima Gomes',
    role: 'Frontend Developer',
    organisation: 'SE DC APB M CAT',
    email: 'fatima.gomes@siemens-energy.com',
  },
  {
    name: 'Daniel Huzioka',
    role: 'Procurement Engineering',
    organisation: 'SE P COE DDM BA',
    email: 'daniel.huzioka@siemens-energy.com',
  },
];

/**
 * Procurement Team List
 * Empty array for procurement team members.
 */
export const procurementTeamList = [];

/**
 * Maintenance Messages
 * Array of objects representing maintenance messages to be displayed.
 */
export const maintenceMessages = [
  {
    severity: 'success',
    summary: '',
    detail: 'Version 3.5 of SUPERIS is now available',
  },
];
