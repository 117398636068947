<nav class="navbar navbar-expand navbar-light">
  <a class="navbar-brand" href="#">
    <img height="auto" width="auto" src="{{ logoUrl }}" />
    <p>{{ appTitle }}</p>
  </a>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav">
      <li class="nav-item" *ngFor="let item of headerLinks">
        <a
          class="nav-link active"
          aria-current="page"
          [href]="item.link.href"
          [target]="item.link.target"
          >{{ item.title }}</a
        >
      </li>
    </ul>
  </div>
</nav>
