/**
 * @NgModule
 * Main application module that sets up core Angular modules and feature modules.
 *
 * - Imports:
 *   - Core modules: BrowserModule, BrowserAnimationsModule, NoopAnimationsModule
 *   - Feature modules: ComponentsModule, LoginModule, RisksModule
 *   - HttpClientModule for HTTP requests
 *   - RouterModule and AppRoutingModule for routing
 *   - MsalModule for Azure AD authentication
 *
 * - Providers:
 *   - HTTP_INTERCEPTORS: MsalInterceptor for handling Azure AD tokens
 *   - MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG: Configuration for MSAL
 *   - MsalService, MsalGuard, MsalBroadcastService: MSAL services
 *
 * - Bootstrap:
 *   - AppComponent: Main app component
 *   - MsalRedirectComponent: Handles MSAL redirects
 */

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  ɵHttpInterceptorHandler,
} from '@angular/common/http';

import { AppComponent } from './app.component';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';

import { ComponentsModule } from './core/components/components.module';
import { FakeBackendInterceptorService } from './core/services/fake-backend.interceptor';

import {
  MsalRedirectComponent,
  MsalGuard,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptor,
  MsalService,
  MsalBroadcastService,
  MsalModule,
} from '@azure/msal-angular';

import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './core/helpers/azure-auth-config';

import { LoginModule } from './features/login/login.module';
import { RisksModule } from './features/risks/risks.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    ComponentsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    LoginModule,
    RisksModule,
    MsalModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: FakeBackendInterceptorService,
    //   multi: true,
    //   deps: [ɵHttpInterceptorHandler],
    // },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
