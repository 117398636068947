// version.service.ts
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@core/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

/**
 * Service for managing application version and local storage.
 */
export class VersionService {
  private storageKey = 'appVersion';
  private currentVersion$ = new BehaviorSubject(environment.version);
  private BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  /**
   * Checks and updates the current application version.
   * Clears local storage if the version has changed.
   */
  checkVersion(): void {
    this.http.get(`${this.BASE_URL}db_version`).subscribe((data) => {
      const storedVersion = localStorage.getItem(this.storageKey);
      this.currentVersion$.next(Object.values(data[0])[0] as string);
      if (storedVersion && storedVersion !== Object.values(data[0])[0]) {
        this.clearLocalStorage();
      }
      localStorage.setItem(
        this.storageKey,
        Object.values(data[0])[0] as string
      );
    });
  }

  /**
   * Clears local and session storage.
   */
  private clearLocalStorage(): void {
    localStorage.removeItem('appVersion');
    localStorage.removeItem('state-session-filters-risk');
    sessionStorage.clear();
  }

  /**
   * Gets the current application version as an observable.
   * @returns Observable<string> The current version.
   */
  getVersion(): Observable<string> {
    return this.currentVersion$;
  }
}
