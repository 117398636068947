import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'se-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent {
  @Input() date: Date;
  @Input() isDisabled: boolean;
  @Output() changedDate: EventEmitter<Date> = new EventEmitter<Date>();
  @ViewChild('calendar', { static: false }) calendar: ElementRef;
  // icons
  faCalendar = faCalendar;
  private clearButtonInitialized = false;

  ngAfterViewInit() {
    this.setupClearButtonListener();
  }

  private async setupClearButtonListener() {
    if (this.clearButtonInitialized) {
      return;
    }

    if (this.calendar) {
      await this.waitForDOMUpdate();

      const calendarElement = this.calendar.nativeElement as HTMLElement;
      const buttonBar = calendarElement.querySelector('.p-button-bar');

      if (buttonBar) {
        const clearButton = buttonBar.querySelector(
          '.p-button.p-button-text.p-button-clear'
        );

        if (clearButton) {
          clearButton.removeEventListener('click', this.onClearButtonClick);
          clearButton.addEventListener('click', this.onClearButtonClick);
          this.clearButtonInitialized = true;
        }
      }
    }
  }

  private waitForDOMUpdate(): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, 10));
  }

  private onClearButtonClick = () => {
    this.clearDate();
  };

  clearDate() {
    this.date = null;
    this.changedDate.emit(null);
  }

  /**
   * Handles the date change event and emits the new date.
   * @param {Date} date - The selected date.
   */
  onDateChange(date: Date) {
    if (date !== null) {
      this.date = date;
      this.changedDate.emit(date);
    } else {
      this.clearDate(); 
      this.date = new Date();
      this.changedDate.emit(this.date); // Emit the current date or null (if cleared)
    }
  }
}
