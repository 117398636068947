import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'se-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterDropdownComponent {
  @Input() valueList: any[];
  @Input() set preSelected(value: any) {
    this._preSelected = value;
    this.updateSelectedValues();
  }
  @Output() eventFilters = new EventEmitter<any>();

  selectedValues = [];
  selectAll = false;
  private _preSelected: any;

  /**
   * Initializes the component and updates selected values.
   */
  ngOnInit() {
    this.updateSelectedValues();
  }

  /**
   * Handles the 'select all' checkbox change event.
   * @param {Event} event - The checkbox change event.
   */
  onSelectAllChange(event) {
    this.selectAll = event.checked;
  }

  /**
   * Logs the filtered values from the event.
   * @param {any} event - The filter event containing filtered values.
   */
  onFilter(event) {
    // The filtered values will be available in the event.filteredValue property
    console.log(event);
  }

  /**
   * Updates the selected values based on the filter values.
   * @param {any[]} values - The selected filter values.
   */
  filterChange(values) {
    this.selectedValues = values;
  }

  /**
   * Determines if 'select all' should be enabled based on the value list length.
   * @returns {boolean} - `true` if the list length is less than 4000, otherwise `false`.
   */
  checkFilterSelectAll() {
    return this.valueList && this.valueList.length < 4000 ? true : false;
  }

  /**
   * Emits the current selection of values.
   */
  saveSelection() {
    this.eventFilters.emit(this.selectedValues);
  }

  /**
   * Clears the current selection and emits the change.
   */
  clearSelection() {
    this.filterChange(null);
    this.saveSelection();
  }

  /**
   * Updates the selected values based on the pre-selected value and value list.
   * @private
   */
  private updateSelectedValues() {
    if (this._preSelected === null) {
      this.selectedValues = [];
    } else if (this._preSelected === '') {
      const result = this.valueList.filter((item) => item.label === '');
      this.selectedValues = result;
    } else if (this.valueList) {
      const labels = this._preSelected.split(',');
      const result = this.valueList.filter((item) =>
        labels.includes(item.label.replace(/,/g, '$'))
      );
      this.selectedValues = result;
    } else {
      this.selectedValues = [];
    }
  }
}
