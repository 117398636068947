<select
  [title]="selectedOption"
  [(ngModel)]="selectedOption"
  [ngStyle]="{'background-color': getColor()}"
  [ngClass]="
    'se-dropdown se-dropdown__bg--' +
    getColor() +
    (isDarkColor ? ' dark' : '') +
    (hasValidationErrors ? ' se-dropdown--mandatory' : '')
  "
  *ngIf="!isDisabled"
  (change)="onSelectChange($event)"
>
  <option *ngFor="let values of dropdownValues" [value]="values.value">
    {{ isTransformEnabled ? transformString(values.value) : values.value }}
  </option>
</select>
<div
  *ngIf="isDisabled"
  [class]="
    'se-dropdown se-dropdown--disabled se-dropdown__bg--' +
    getColor() +
    (isDarkColor ? ' dark' : '')
  "
>
  {{ isTransformEnabled ? transformString(selectedOption) :  selectedOption}}
</div>
