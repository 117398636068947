<se-table
  [tableHeaders]="riskTableHeaders"
  [tableHeadersGroup]="riskTableHeadersGroup"
  [tableData]="riskTableData"
  [tableConfig]="riskTableConfig"
  [tableFilters]="riskTableFilters"
  [tableDropdowns]="riskTableDropdowns"
  [totalActionPlans]="totalActionPlans"
  [dataLoading] = "dataLoading"
  [valuesFiltered]="filter"
  [tableTotalValues]="totalRisks"
  (eventActions)="handleRowAction($event)"
  (eventFilters)="updateFilter($event)"
  (updateRow)="updateField($event)"
  (loadMore)="getRiskTableDataAndTotals(true)"
></se-table>
